import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { AiFillEye } from "react-icons/ai";
import { BsTrash3 } from "react-icons/bs";
import { AlertInfo } from "../../../common/Alert";
import TableSkeleton from "../../../common/TableSkeleton";
import Profil from "../../../../assets/appImages/avatar1.png";
import { SousAdminTableData } from "./SousAdminData";
import { MdEdit } from "react-icons/md";
import { useGetListAdminUserQuery } from "../../../../utils/api/user/user.api";
import { useAppSelector } from "../../../../redux/hook";
import { isSuperAdmin } from "../../../../routes/routerUtils";
import { CustomPagination } from "../../../common/CustomPagination";
import { NavLink } from "react-router-dom";
import { getAvatar } from "../../../../utils/Utils";
import { IUser } from "../../../../utils/api/user/user.type";
import { UseDeleteAdmin } from "../requestForm/UseCrudAdmin";

const DeleteBtn = ({ slug }: { slug: string }) => {
  const onDelete = UseDeleteAdmin(slug);
  return (
    <div className="container-btn-action-icon-modal">
      <button
        className="btn with-tooltip btn-action-icon-delete"
        data-tooltip-content="Supprimer"
        style={{ color: "#E53E3E" }}
        onClick={onDelete}
      >
        <BsTrash3 />
      </button>
    </div>
  );
};
function SousAdminTable() {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { data = { results: [], count: 0 }, isLoading } =
    useGetListAdminUserQuery({
      limit: perPage,
      page,
    });

  
  const actionFormatter = (cell: string, row: any) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-3">
          <div className="container-btn-action-icon-modal">
            <NavLink
              to={`/admin/modifier-admin/${cell}`}
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Modifier"
              state={row}
            >
              <MdEdit />
            </NavLink>
          </div>
          <DeleteBtn slug={cell} />
        </div>
      </>
    );
  };
  const nameFormatter = (cell: any, row: IUser) => {
    return (
      <div className="d-flex gap-3 align-items-center">
        <div className="content-img-profil-itemt-table">
          <img
            src={getAvatar(row?.avatar)}
            alt={cell + row?.prenom}
            className="img-profil-itemt-table"
          />
        </div>
        <p className="name-profil-patient-table mb-0">
          {row?.prenom + " " + cell}
        </p>
      </div>
    );
  };
  const columns = [
    {
      dataField: "nom",
      text: `Nom`,
      formatter: nameFormatter,
      headerStyle: () => {
        return { width: "200px", whiteSpace: "normal" };
      },
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "telephone",
      text: "Téléphone",
    },
    {
      dataField: "slug",
      text: "Action",
      formatter: actionFormatter,
      headerStyle: () => {
        return { width: "100px", whiteSpace: "normal" };
      },
    },
  ];
  const selectRow = {
    mode: "checkbox",
  };
  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            // selectRow={selectRow}
            condensed
            hover
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvée" />
            )}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setPerPage(perPage);
                setPage(page);
              }}
              perPage={perPage}
            />
          </div>
        </>
      )}
    </>
  );
}

export default SousAdminTable;
