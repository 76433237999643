import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "./ConseilAdministration.css";
import { useStepType } from "../../../utils/type";
import { useStep } from "react-hooks-helper";
import MembresTabs from "./tabs/MembresTabs";
import ReunionsTabs from "./tabs/ReunionsTabs";
import Select from "react-select";
import { HiOutlineBars3BottomRight } from "react-icons/hi2";
import AddContenuMeetingModal from "./modal/AddContenuMeetingModal";
import AddMeetingModal from "./modal/AddMeetingModal";
import { useGetPaysQuery } from "../../../utils/api/pays/pays.api";

const steps = [
  { id: "membres", Component: MembresTabs },
  { id: "reunions", Component: ReunionsTabs },
];
const tabs = ["Membres", "Réunions"];

function ConseilAdministration() {
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;
  const [options, setOptions] = useState<{ label: string; value: number }[]>(
    []
  );
  const [option, setOption] = useState<{ label: string; value: number }>();
  const [inputValue, setInputValue] = useState("");
  const { data = { results: [] } } = useGetPaysQuery({
    q: inputValue,
    limit: 1000,
  });
  const [modalAddContenuMeeting, setModalAddContenuMeeting] = useState(false);
  const [modalAddMeeting, setModalAddMeeting] = useState(false);
  const [membreType, setMembreType] = useState("");

  useEffect(() => {
    if (data?.results?.length) {
      setOptions(
        data?.results?.map((el) => {
          return {
            label: el?.nom,
            value: el?.id,
          };
        })
      );
    }
  }, [data]);
  console.log('pays', data)

  function openModalAddContenuMeeting() {
    setModalAddContenuMeeting(true);
  }
  function openModalAddMeeting() {
    setModalAddMeeting(true);
  }

  const [query, setQuery] = useState("");

  const props = {
    openModalAddMeeting,
    setModalAddMeeting,
    modalAddMeeting,
    setModalAddContenuMeeting,
    query,
    pays: option?.value || "",
    q_type: "conseil_administration",
  };
  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <h2 className="chart-item-admin-title mb-4">
          Conseil d'administration
        </h2>
        <div className="content-graph-admin">
          <div className="eglise-dash-tabs mb-0">
            <ul className="tabs-container tabs-container-admin">
              {tabs.map((tab: any, key: any) => (
                <li
                  className={`tab-item  ${
                    index === key && "tab-active-item-admin"
                  }`}
                  key={key}
                  onClick={() => go(key)}
                  role="button"
                >
                  {tab}
                </li>
              ))}
            </ul>
          </div>
          {index === 0 && (
            <div className="row country-and-search-bar-content pb-4">
              <div className="col-md-3 country-selection">
                <Select
                  options={options || []}
                  onChange={(val: any) => setOption(val)}
                  value={option}
                  onInputChange={(val) => setInputValue(val)}
                  isClearable
                  placeholder="Pays"
                />
              </div>
              <div className="col-md-4 search-bar-container d-flex align-items-center gap-2">
                <input
                  type="search"
                  placeholder="Chercher"
                  className="search-bar-conseil"
                  onChange={(e) => setQuery(e?.target?.value)}
                />
                {/* <span className="icon-container">
                  <HiOutlineBars3BottomRight />
                </span> */}
              </div>
            </div>
          )}

          <div className="content-btn-theme-title pt-4">
            {index === 0 ? (
              <>
                <NavLink
                  to="/admin/conseil-administration/add-member"
                  className="btn btn-theme-admin"
                  state={"conseil_administration"}
                >
                  <span>Nouveau membre</span>
                </NavLink>
              </>
            ) : (
              <div className="row align-items-center">
                <div className="col-lg-9 col-md-12">
                  <div className="btn-nouveau-contenu-container">
                    <div className="">
                      <button
                        className="btn btn-theme-admin"
                        onClick={() => {
                          setMembreType("conseil_administration");
                          openModalAddContenuMeeting();
                        }}
                      >
                        <span>Nouveau contenu</span>
                      </button>
                    </div>
                    <div className="">
                      <button
                        className="btn btn-theme-admin"
                        onClick={() => {
                          setMembreType("conseil_administration");
                          openModalAddMeeting();
                        }}
                      >
                        <span>Programmer une réunion</span>
                      </button>
                    </div>
                  </div>

                  <AddContenuMeetingModal
                    modalAddContenuMeeting={modalAddContenuMeeting}
                    setModalAddContenuMeeting={setModalAddContenuMeeting}
                    membre_type={membreType}
                    compteRendu={undefined}
                  />
                  <AddMeetingModal
                    modalAddMeeting={modalAddMeeting}
                    setModalAddMeeting={setModalAddMeeting}
                    membre_type={membreType}
                    reunion={undefined}
                  />
                </div>
                <div className="col-lg-3 col-md-6">
                  <h2 className="title-tab-reunion mb-0">
                    Prochaines réunions
                  </h2>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="tabs-container">
          <Component {...props} />
        </div>
      </div>
    </div>
  );
}

export default ConseilAdministration;
