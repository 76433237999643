import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { getAvatar } from '../../../utils/Utils';
import { AlertInfo } from '../../common/Alert';
import { CustomPagination } from '../../common/CustomPagination';
import PasteurAdminSkeleton from '../PasteursAdmin/PasteurAdminSkeleton';
import { useGetSecretariesQuery } from '../../../utils/api/secretary/secretary.api';



const SecretariesAdmin = () => {
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(12);
    const { data, isLoading } = useGetSecretariesQuery({
        page,
        limit: perPage,
    });

    return (
        <div className="dashbord-admin-component">
            <div className="dash-admin-page-content-container mb-3">
                <h2 className="chart-item-admin-title mb-4">Sécrétaires</h2>
                <div className="content-graph-admin">
                    <div className="container-title-dash">
                        <div className="content-btn-theme-title">
                            <NavLink
                                to="/admin/secretaries/add-new"
                                className="btn btn-theme-admin"
                            >
                                Nouveau(elle) sécrétaire
                            </NavLink>
                        </div>
                    </div>
                <div className="row py-5">
                    {!!isLoading && <PasteurAdminSkeleton />}
                    {!isLoading &&
                    (!!data && data?.results?.length > 0 ? (
                        data?.results?.map((item) => (
                        <div
                            className="col-lg-3 col-md-6 col-item-item text-center mb-3 d-flex"
                            key={item?.slug}
                        >
                            <div className="content-col-item-item w-100 d-flex flex-column">
                                <div className="content-img-profil-item py-3">
                                    <img
                                        src={getAvatar(item?.avatar)}
                                        alt={item?.prenom}
                                        className="pasteur-img-avatar"
                                    />
                                </div>
                                <p className="name-item-admin">
                                    {item?.prenom + " " + item?.nom}
                                </p>
                                {!!item?.type_secretaire && (
                                <p className="py-2">
                                    <label>Sécrétaire {item?.type_secretaire}</label>
                                </p>)}
                                <div className="container-btn-viez-profil-item pt-3 mt-auto">
                                    <NavLink
                                        to={`/admin/secretaries/${item?.slug}`}
                                        className="btn btn-view-profil-item"
                                        state={item}
                                    >
                                        Voir le profil
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                        ))
                    ) : (
                        <AlertInfo message="Aucune donnée trouvée" />
                    ))}
                </div>
                {data?.results && (
                <div className="custom-pagination-container">
                    <CustomPagination
                        nbPages={data?.count}
                        page={page}
                        onChange={(page, perPage) => {
                            setPerPage(perPage);
                            setPage(page);
                        }}
                        perPage={perPage}
                    />
                </div>)}
                </div>
            </div>
        </div>
    )
}

export default SecretariesAdmin;