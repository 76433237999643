import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import {
    ISecretary,
    SecretaryFormData
} from "../../../../utils/api/secretary/secretary.type"
import { useAddOrEditSecretaryMutation } from "../../../../utils/api/secretary/secretary.api";
import { cleannerError, validatePhone } from "../../../../utils/Utils";
import { Color } from "../../../../utils/theme";
import { Data } from "../../../common/GoogleInput";
import { IUser } from "../../../../utils/api/user/user.type";
import moment from 'moment';


function UseCrudSecretaryForm(secretary?: ISecretary | IUser) {
    const [code, setCode] = useState<string>("");
    const [phone, setPhone] = useState<string>();
    const validationSchema = yup.object().shape({
        prenom: yup.string().required("Prenom est obligatoire").label("Prénom"),
        nom: yup.string().required("Nom est obligatoire").label("Nom"),
        email: yup
        .string()
        .email("L'email est invalide")
        .required("Email est obligatoire"),
        telephone: validatePhone(yup, code),
        adresse: yup.string().label("Adresse").required().nullable(),
    });
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        reset,
        clearErrors,
    } = useForm<SecretaryFormData>({
        resolver: yupResolver(validationSchema),
    });
    const [address, setAddress] = useState("");
    const [content, setContent] = useState<string>("");
    const [addOrUpdateSecretary, { isLoading, isSuccess, error, isError }] =
        useAddOrEditSecretaryMutation();

    const navigate = useNavigate();

    const handleChangePhone = (item, country) => {
        setCode(country?.countryCode);
        setPhone(item);
        if (parseInt(item) !== parseInt(country?.dialCode)) {
        setValue(`telephone`, "+" + item);
        } else {
        setValue(`telephone`, "");
        }
    };

    const onChangeAddress = (val: Data) => {
        setAddress(val?.rue);
        setValue("adresse", val?.rue);
    };

    useEffect(() => {
        if (isSuccess) {
        Swal.fire({
            icon: "success",
            title: secretary
            ? "Sécrétaire modifié(e) avec succès !"
            : "Sécrétaire ajouté(e) avec succès",
            iconColor: Color.success,
            showConfirmButton: false,
            timer: 2000,
        }).then(() => {
            if (!secretary) {
            reset();
            setContent("");
            }
            navigate("/admin/secretaries");
        });
        }
        const err = error as any;
        if (isError) {
        if (err?.data?.email?.includes("l'utilisateur avec cet email existe déjà.")) {
            Swal.fire({
            icon: "error",
            title: "Un utilisateur avec cet e-mail existe déjà.",
            showConfirmButton: false,
            timer: 2000,
            });
        } else {
            Swal.fire({
            icon: "error",
            title: err?.data?.message
                ? err?.data?.message
                : `Error status ${err?.status}`,
            showConfirmButton: false,
            timer: 5000,
            });
        }
        }
    }, [isLoading]);
    useEffect(() => {
        cleannerError(errors, clearErrors);
    }, [errors]);

    const onSubmit = (data: any) => {
        let fd = new FormData();
        for (let key of Object.keys(data)) {
        fd.append(key, data[key]);
        }
        addOrUpdateSecretary({ slug: secretary?.slug, data: fd });
    };

    const handleReset = () => {
        if (!secretary) {
        reset();
        }
    };

    useEffect(() => {
        if (secretary?.id) {
            const fields: (keyof SecretaryFormData)[] = [
                "prenom",
                "nom",
                "email",
                "telephone",
                "adresse",
            ];
            for (let field of fields) {
                setValue(field, secretary[field]);
            }
            if (secretary?.telephone) {
                setPhone(secretary?.telephone);
            }
            setAddress(secretary?.adresse);
        }
    }, [secretary]);

    return {
        register,
        errors: errors,
        onSubmit: handleSubmit(onSubmit),
        setValue,
        isLoading,
        content,
        handleReset,
        phone,
        handleChangePhone,
        address,
        onChangeAddress,
    };
}

export default UseCrudSecretaryForm;

function parseDate(dateString: string): moment.Moment | null {
  const formats = ['YYYY-MM-DD', 'DD/MM/YYYY', 'MM/DD/YYYY'];
  const parsedDate = moment(dateString, formats, true);
  return parsedDate.isValid() ? parsedDate : null;
}

function formatDate(date: Date | string): string {
  return moment(date).format('YYYY-MM-DD');
}