import React from "react";
import BreadCrumb from "../../shared/BreadCrumb";
import { FormError, Input, animatedComponents } from "../../common/Input";
import Select from "react-select";
import MyDropzone, { ImageItem } from "../../common/MyDropzone";
import ReactQuill from "react-quill";
import { useObjetReligieuxFromLocation } from "../../../utils/api/objetReligieux/objetReligieux.api";
import AddOrUpdateForm from "./form/AddOrUpdateForm";
import { useNavigate } from "react-router-dom";
import GoogleInput from "../../common/GoogleInput";
import PhoneInput from "react-phone-input-2";

function AddOrUpdateObjet() {
  const [objetReligieux] = useObjetReligieuxFromLocation();
  const { 
    register, 
    errors, 
    onSubmit, 
    isLoading, 
    image, 
    setImage,
    Controller,
    control,
    phone,
    handleChangePhone,
    address,
    onChangeAddress, 
    pays,
    selectedPays,
    onSelectPays,
    description,
    handleChangeDescription,
    photoMagazin,
    setPhotoMagazin
  } = AddOrUpdateForm(objetReligieux);
  const navigate = useNavigate();

  return (
    <>
      <div className="dashbord-admin-component">
        <div className="dash-admin-page-content-container mb-3">
          {!!objetReligieux ? (
            <BreadCrumb
              pageName="Objets religieux"
              secondPage="Modifier un objet"
            />
          ) : (
            <BreadCrumb
              pageName="Objets religieux"
              secondPage="Ajouter un nouvel objet"
            />
          )}
        </div>
        <div className="btn-action-container">
          <span className="egs__text">Ajouter un objet religieux</span>
        </div>
        <div className="form-content mt-2 bg-white p-3 pb-5">
          <form onSubmit={onSubmit}>
            <div className="row">
              <div className="col-md-12">
                <Input
                  label="Nom"
                  type="text"
                  placeholder="Nom"
                  {...register("nom")}
                  error={errors?.nom}
                  required
                />
              </div>
              <div className="col-md-12 mb-3">
                <label className="form-label form-label-modal-custom">
                  Image de l'objet{" "}
                  <span className="text-danger" style={{ fontSize: 15 }}>
                      *
                    </span>
                </label>
                <MyDropzone
                  setImages={setImage}
                  acceptFile={{
                    "image/jpeg": [],
                    "image/png": [],
                    "image/jpg": [],
                    "image/gif": [],
                  }}
                  maxFile={1}
                />
                {image || objetReligieux?.image ? (
                  <ImageItem image={image || objetReligieux?.image} />
                ) : null}
                <FormError error={errors?.image} />
              </div>
              <div className="col-md-12">
                <Input
                  label="Nom du magasin"
                  type="text"
                  placeholder="Nom du magasin"
                  {...register("nom_magasin")}
                />
              </div>
              <div className="col-md-12 mb-3">
                <label className="form-label form-label-modal-custom">
                  Photo du magasin{" "}
                </label>
                <MyDropzone
                  setImages={setPhotoMagazin}
                  acceptFile={{
                    "image/jpeg": [],
                    "image/png": [],
                    "image/jpg": [],
                    "image/gif": [],
                  }}
                  maxFile={1}
                />
                {photoMagazin || objetReligieux?.photo_magasin ? (
                  <ImageItem image={photoMagazin || objetReligieux?.photo_magasin} />
                ) : null}
                <FormError error={errors?.photo_magasin} />
              </div>
              <div className="col-md-12 mb-3">
                <label
                    htmlFor={"tel_magazin"}
                    className={`form-label form-label-modal-custom`}
                    aria-labelledby={"tel_magazin"}
                >
                    Téléphone du magasin
                </label>
                <PhoneInput
                    defaultMask={".. ... .. .."}
                    containerClass="phone-input-admin"
                    inputClass="form-control custom-padding"
                    country={"sn"}
                    placeholder=""
                    inputProps={{
                    name: "tel_magazin",
                    required: true,
                    autoFocus: true,
                    }}
                    countryCodeEditable={false}
                    enableAreaCodes={true}
                    prefix="+"
                    value={phone}
                    onChange={(phone, country: any) => {
                      handleChangePhone(phone, country);
                    }}
                    inputStyle={{
                    width: "100%",
                    paddingBottom: "22px",
                    borderRadius: "10px",
                    paddingTop: "22px",
                    height: "54px",
                    backgroundColor: "#F2F6FA",
                    border: "none",
                    }}
                    dropdownStyle={{
                      backgroundColor: "#F2F6FA",
                    }}
                    enableLongNumbers={true}
                />
                <FormError error={errors?.tel_magasin?.message} />
              </div>
              <div className="col-md-12 mb-3">
                <label
                    htmlFor={"address"}
                    className={`form-label form-label-modal-custom`}
                    aria-labelledby={"address"}
                >
                    Adresse du magasin{" "}
                </label>
                <GoogleInput
                    placeholder="Adresse"
                    className={`form-control form-control-modal-custom`}
                    value={address}
                    onChange={onChangeAddress}
                />
                <FormError error={errors?.adresse?.message} />
              </div>
              <div className="col-md-12">
                <div className="mb-4">
                  <label
                    htmlFor={"pays"}
                    className={`form-label form-label-modal-custom`}
                    aria-labelledby={"pays"}
                  >
                    Pays{" "}
                  </label>
                  <Controller
                    name="pays"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Select
                          {...field}
                          closeMenuOnSelect={true}
                          components={animatedComponents}
                          options={pays}
                          classNamePrefix="react-select-custom"
                          onChange={onSelectPays}
                          value={selectedPays}
                        />
                      );
                    }}
                  />
                  <FormError error={errors.pays} />
                </div>
              </div>
              <div className="col-md-12 mb-3">
                <Input
                  label="Adresse mail du magasin"
                  type="email"
                  placeholder="Adresse mail"
                  {...register("email_magasin")}
                />
              </div>
              <div className="col-md-12 mb-3">
                <label
                  htmlFor={"description_magasin"}
                  className={`form-label form-label-modal-custom`}
                  aria-labelledby={"description_magasin"}
                >
                  Description du magasin
                </label>
                <ReactQuill
                  className="editor-cgu"
                  theme="snow"
                  value={description}
                  onChange={handleChangeDescription}
                />
              </div>
            </div>
            <div className="col-md-4 offset-md-8 auth-submit-btn-container text-end pt-3">
              <button
                disabled={isLoading}
                className="btn btn-theme-cancel w-100"
                type="button"
                onClick={() => navigate(-1)}
              >
                Retour
              </button>
              <button
                disabled={isLoading}
                className="btn btn-theme-admin w-100"
                type="submit"
              >
                {isLoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                      role="status"
                    ></span>
                    <span>
                      {objetReligieux ? "Modification..." : "Ajout..."}
                    </span>
                  </>
                ) : objetReligieux ? (
                  "Modifier"
                ) : (
                  "Ajouter"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddOrUpdateObjet;
