import React from 'react';
import BreadCrumb from '../../shared/BreadCrumb';
import { FormError, Input } from '../../common/Input';
import PhoneInput from 'react-phone-input-2';
import GoogleInput from '../../common/GoogleInput';
import UseCrudSecretaryForm from './form/UseCrudSecretaryForm';
import { useUserFromLocation } from '../../../utils/api/user/user.api';



const AddOrUpdateSecretaryAdmin = () => {

    const [item] = useUserFromLocation();
    const {
        register,
        onSubmit,
        errors,
        isLoading,
        phone,
        handleChangePhone,
        address,
        onChangeAddress,
    } = UseCrudSecretaryForm(item);

    return (
        <div className="dashbord-admin-component">
            <div className="dash-admin-page-content-container mb-3">
                <div className="row mb-4">
                    <BreadCrumb pageName="Sécrétaires" secondPage="Nouveau(elle) sécrétaire" />
                </div>
                <div className="content-graph-admin medsain-page-title-container mb-2">
                    <h2 className="chart-item-admin-title mb-4">
                        Nouveau sécrétaire
                    </h2>
                    <div className="form-add-container">
                        <form className="mt-4" onSubmit={onSubmit}>
                            <div className="row row-add-student my-3">
                            <div className="col-md-6 mb-3">
                                <Input
                                    type="text"
                                    label="Nom"
                                    id="nom"
                                    placeholder="Nom"
                                    {...register("nom")}
                                    error={errors.nom}
                                    required
                                />
                            </div>
                            <div className="col-md-6 mb-3">
                                <Input
                                    type="text"
                                    label="Prénom"
                                    id="prenom"
                                    placeholder="Prénom"
                                    {...register("prenom")}
                                    error={errors.prenom}
                                    required
                                />
                            </div>
                            <div className="col-md-6 mb-3">
                                <Input
                                    type="email"
                                    label="Email"
                                    id="email"
                                    placeholder="Email"
                                    {...register("email")}
                                    error={errors.email}
                                    required
                                />
                            </div>
                            <div className="col-md-6 mb-3">
                                <div className="mb-3">
                                <label
                                    htmlFor={"telephone"}
                                    className={`form-label form-label-modal-custom`}
                                    aria-labelledby={"telephone"}
                                >
                                    Téléphone
                                </label>
                                <PhoneInput
                                    defaultMask={".. ... .. .."}
                                    containerClass="phone-input-admin"
                                    inputClass="form-control custom-padding"
                                    country={"sn"}
                                    placeholder=""
                                    inputProps={{
                                    name: "telephone",
                                    required: true,
                                    autoFocus: true,
                                    }}
                                    countryCodeEditable={false}
                                    enableAreaCodes={true}
                                    prefix="+"
                                    value={phone}
                                    onChange={(phone, country: any) => {
                                    handleChangePhone(phone, country);
                                    }}
                                    inputStyle={{
                                    width: "100%",
                                    paddingBottom: "22px",
                                    borderRadius: "10px",
                                    paddingTop: "22px",
                                    height: "54px",
                                    backgroundColor: "#F2F6FA",
                                    border: "none",
                                    }}
                                    dropdownStyle={{
                                    backgroundColor: "#F2F6FA",
                                    }}
                                    enableLongNumbers={true}
                                />
                                <FormError error={errors?.telephone?.message} />
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <Input
                                    type="text"
                                    label="Type de sécrétaire"
                                    id="__secretary_type"
                                    placeholder="Email"
                                    {...register("type_secretaire")}
                                    error={errors.type_secretaire}
                                />
                            </div>
                            <div className="col-md-6 mb-3">
                                <div className="mb-3">
                                <label
                                    htmlFor={"address"}
                                    className={`form-label form-label-modal-custom`}
                                    aria-labelledby={"address"}
                                >
                                    Adresse{" "}
                                    <span className="text-danger" style={{ fontSize: 15 }}>
                                    *
                                    </span>
                                </label>
                                <GoogleInput
                                    placeholder="Adresse"
                                    className={`form-control form-control-modal-custom`}
                                    value={address}
                                    onChange={onChangeAddress}
                                />
                                <FormError error={errors?.adresse?.message} />
                                </div>
                            </div>
                            <div className="col-md-3 offset-md-9 auth-submit-btn-container text-end pt-5">
                                <button
                                    className="btn btn-theme-admin w-100"
                                    disabled={isLoading}
                                    type="submit"
                                    >
                                    {isLoading ? (
                                        <>
                                        <span
                                            className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                                            role="status"
                                        ></span>
                                        <span>{item ? "Modification..." : "Ajout..."}</span>
                                        </>
                                    ) : item ? (
                                        "Modifier"
                                    ) : (
                                        "Ajouter"
                                    )}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddOrUpdateSecretaryAdmin;