import React from "react";
import "./PreambuleAdmin.css";
import { NavLink } from "react-router-dom";
import { useGetPreambuleQuery } from "../../../utils/api/preambule/preambule.api";
import { createMarkup } from "../../../utils/Utils";
import { AlertInfo } from "../../common/Alert";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { UseDeletePreambule } from "./requestForm/UseCrudPreambule";
import { IPreambule } from "../../../utils/api/preambule/preambule.type";
import PreambuleAdminSkeleton from "./PreambuleAdminSkeleton";

function PreambuleAdmin() {
  const { data, isLoading } = useGetPreambuleQuery({});
  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <h2 className="chart-item-admin-title mb-4">Préambule</h2>
        <div className="content-graph-admin">
          <div className="container-title-dash">
            <div className="content-btn-theme-title">
              <NavLink
                to="/admin/ajouter-preambule"
                state={data?.results[0]}
                className="btn btn-theme-admin"
              >
                {!!data && data?.results?.length > 0
                  ? "Mettre à jour"
                  : "Ajouter"}
              </NavLink>
            </div>
          </div>
          {!!isLoading && <PreambuleAdminSkeleton />}
          {!isLoading &&
            (!!data && data?.results?.length > 0 ? (
              data?.results?.map((item) => (
                <div key={item?.slug}>
                  <div className="container-list-preambule-admin my-4">
                    <ol>
                      <li className="list-item-preambule-admin">
                        {item?.titre}
                      </li>
                    </ol>
                  </div>
                  <div className="container-page-preambule-admin">
                    <div className="flex-r">
                      <div className="btn-group">
                        <button
                          className="btn btn-secondary btn-sm dropdown-toggle documentation-archive-custom-btn"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <BiDotsHorizontalRounded />
                        </button>
                        <ul className="dropdown-menu">
                          <li>
                            <DeletePreambuleBtn item={item} />
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="container-text-preambule-admin pt-0">
                      <p
                        className="text-preambule-admin"
                        dangerouslySetInnerHTML={createMarkup(item?.content)}
                      />
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <AlertInfo message="Aucune donnée trouvée" />
            ))}
        </div>
      </div>
    </div>
  );
}

export default PreambuleAdmin;

function DeletePreambuleBtn({ item }: { item: IPreambule }) {
  const onDelete = UseDeletePreambule(item);

  return (
    <button className="dropdown-item" onClick={() => onDelete()}>
      Supprimer
    </button>
  );
}
